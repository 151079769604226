<template>
  <div id="print-html">
    <div class="title">{{
        orderInfo.orderStatus === 1 ? orderHeadName.name.startText : orderHeadName.name.overText
      }}
    </div>
    <div class="order-info dp_f">
      <div class="l">订单编号:{{ query.orderSn }}</div>
      <div class="r">
        创建时间:{{ orderInfo.time }}
      </div>
    </div>
    <table class="tabList one">
      <tr>
        <th class="head-title">{{ orderHeadName.stockName }}</th>
        <th>{{ orderInfo.stockName }}</th>
        <th class="head-title">{{ '创建人' }}</th>
        <th>{{ orderInfo.userName }}</th>
      </tr>
    </table>
    <table class="tabList two">
      <tr style="width: 100%;">
        <th class="head-title">{{ orderHeadName.thirdName }}</th>
        <th >
          <div style="max-width:160mm;overflow: hidden;">{{ orderInfo.thirdName }}</div>
        </th>
      </tr>
    </table>
    <table class="tabList two">
      <tr>
        <th class="index-title">序号</th>
        <th class="info-title">产品名称</th>
        <th class="info-title">规格/型号</th>
        <th class="info-title">产品单位</th>
        <th class="info-title">产品品牌</th>
        <th class="info-title">{{ isOver ? orderHeadName.name.countText : '计划数量' }}</th>
        <th class="info-title">单价(元)</th>
        <th class="info-title">小计(元)</th>
      </tr>
      <tr v-for="(item,index) in tableData" :key="item.modelId">
        <td>{{ index + 1 }}</td>
        <td>{{ item.productName }}</td>
        <td>{{ item.modelName }}</td>
        <td v-html=" getUnitName(item)"></td>
        <td>{{ item.brandName }}</td>
        <td>{{ isOver ? item.realityCount : item.count }}</td>
        <td>{{ $filters.toYuan(item.price, '元') }}</td>
        <td>{{ isOver ? $filters.toYuan(item.realityTotalPrice, '元') : $filters.toYuan(item.totalPrice, '元') }}</td>
      </tr>
    </table>
    <table class="tabList two">
      <tr>
        <th class="head-title">合计数量</th>
        <th class="head-value">{{ totalNum.total }}</th>
        <th class="head-title">合计金额（元）</th>
        <th class="head-value">{{ totalNum.price }}</th>
      </tr>
      <tr>
        <th class="head-title">大写金额</th>
        <th class="head-value">{{ totalNum.big }}</th>
        <th class="head-title">备注</th>
        <th class="head-value">{{ orderInfo.remark || '' }}</th>
      </tr>
    </table>
    <table class="tabList two" v-if="isOutOrder(query.orderType) && orderInfo.orderStatus === 1">
      <tr>
        <th class="head-title">出库人</th>
        <th class="head-value">{{ orderInfo.stockUserName }}</th>
        <th class="head-title">出库时间</th>
        <th class="head-value">{{ orderInfo.stockTime }}</th>
      </tr>
    </table>
    <table class="tabList two" v-if="!isOutOrder(query.orderType) && orderInfo.orderStatus === 1">
      <tr>
        <th class="head-title">入库人</th>
        <th class="head-value">{{ orderInfo.stockUserName }}</th>
        <th class="head-title">入库时间</th>
        <th class="head-value">{{ orderInfo.stockTime }}</th>
      </tr>
    </table>
    <table class="tabList two" v-if="isOutOrder(query.orderType) &&orderInfo.orderStatus === 1">
      <tr>
        <th class="head-title">签收人</th>
        <th class="head-value"></th>
        <th class="head-title">签收时间</th>
        <th class="head-value"></th>
      </tr>
    </table>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, reactive, ref} from "vue";
import {convertCurrency, reactiveAssign, resetGoodsList} from "@utils/tool";
import {useRoute} from "vue-router";
import {isOutOrder, orderConfig} from './config.js'
import {orderGoodsList, orderListDeatil} from "@utils/api/order";
import moment from "moment";
import {getUnitName} from "@utils/tool/orderTools";
import Big from 'big.js'

const route = useRoute();
const {query} = route;
const {proxy} = getCurrentInstance();
const tableData = ref([]);

const orderHeadName = reactive({
  name: {},
  stockName: "",
  thirdName: '',
  time: "",
  timeDesc: {}
})
const orderInfo = reactive({
  name: '',
  stockName: "",
  thirdName: '',
  time: "",
  orderSn: '',
  orderStatus: 0,
})
const isOver = computed(() => {
  return orderInfo.orderStatus === 1
})
function setOrder(orderType) {
  let obj = orderConfig[orderType]
  orderHeadName.name = obj.name
  orderHeadName.stockName = obj.stockName
  orderHeadName.thirdName = obj.thirdName
  orderHeadName.timeDesc = obj.timeDesc
}

setOrder(query.orderType)

const getGoodsDetail = () => {
  getOrder()
}

const totalNum = computed(() => {
  let total = 0
  let price = 0
  tableData.value.forEach(item => {
    total += (isOver.value ? item.realityCount : item.count || 0)
    price += (isOver.value ? item.realityTotalPrice : item.totalPrice || 0)
  })
  return {
    total :Number.isInteger(total) ? total : total.toFixed(2),
    price: proxy.$filters.toYuan(price, '元'),
    big: convertCurrency(proxy.$filters.toYuan(price, '元'))
  }
})

const getOrder = () => {
  orderListDeatil({
    orderSn: query.orderSn,
    orderType: query.orderType,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data?.time) {
      res.data.time = moment(res.data.time).format("YYYY年MM月DD日HH时mm分");
    }
    if (res.data?.stockTime) {
      res.data.stockTime = moment(res.data.stockTime).format("YYYY年MM月DD日HH时mm分");
    }
    reactiveAssign(orderInfo, res.data);
    getGoodsList()
  })
}

getGoodsDetail()

const getGoodsList = () => {
  orderGoodsList({
    orderSn: query.orderSn,
    orderType: query.orderType,
    orderStatus: orderInfo.orderStatus,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || [];
    tableData.value = resetGoodsList(list, (item) => {
      item.orderType = query.orderType
    });
    //页面渲染完成后打印
    nextTick(() => {
      proxy.$nextTick(() => {
        // window.print()
      });
    });
  });
};

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
